import {
  forwardRef,
  MouseEvent,
  PointerEvent,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";

import { TextInputContainer, TextInputField, TextInputIcon } from "./TextInput.styles";
import { TextInputProps } from "./TextInput.types";

export const TextInput = forwardRef<HTMLInputElement, PropsWithChildren<TextInputProps>>(
  function Input(
    { fullWidth, icon, type = "text", variant, showsFocus, hasError, children, css, ...props },
    ref
  ) {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handlePointerDown = useCallback((e: PointerEvent<HTMLInputElement>) => {
      // do nothing if clicking on the actual input
      if ((e.target as HTMLElement)?.tagName === "INPUT") {
        return;
      }

      // otherwise focus and swallow event
      inputRef?.current?.focus();
      e.preventDefault();
      e.stopPropagation();
    }, []);

    const handleClick = useCallback((e: MouseEvent<HTMLInputElement>) => {
      if ((e.target as HTMLElement)?.tagName === "INPUT") {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
    }, []);

    return (
      <TextInputContainer
        fullWidth={fullWidth}
        variant={variant}
        showsFocus={showsFocus}
        hasText={!!props.value}
        hasError={!!hasError}
        onPointerDown={handlePointerDown}
        onClick={handleClick}
        css={{ ...css }}
      >
        {icon && <TextInputIcon>{icon}</TextInputIcon>}
        <TextInputField ref={inputRef} {...props} type={type} />
        {children}
      </TextInputContainer>
    );
  }
);
