import * as Accordion from "@radix-ui/react-accordion";

export const { Root, AccordionItem, Item, createAccordionScope } = Accordion;

import { keyframes, styled } from "~/theme";

const slideUp = keyframes({
  "0%": {
    height: "var(--radix-accordion-content-height)",
  },

  "100%": {
    height: "0px",
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const slideDown = keyframes({
  "0%": {
    height: "0px",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "100%": {
    height: "var(--radix-accordion-content-height)",
  },
});

export const Trigger = styled(Accordion.Trigger, {
  height: "48px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 16px",
  "& svg": {
    transition: "transform 150ms ease-in-out",
  },
  "&[data-state=open] svg": {
    transform: "rotate(90deg)",
  },
  borderBottom: "1px solid $grey-800",
});

export const Header = styled(Accordion.Header, {
  width: "100%",

  variants: {
    nonCollapsible: {
      false: {
        "&:hover": {
          backgroundColor: "$grey-800",
        },
      },
    },
  },

  defaultVariants: {
    nonCollapsible: false,
  },
});

export const Content = styled(Accordion.Content, {
  padding: "$16",
  borderBottom: "1px solid $grey-800",
  overflow: "hidden",
  "&[data-state=open]": {
    animation: `${slideDown} 150ms ease-in-out`,
  },
  "&[data-state=closed]": {
    animation: `${slideUp} 150ms ease-in-out`,
  },
  variants: {
    noPadding: {
      true: {
        padding: 0,
      },
    },
  },
});
