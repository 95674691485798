import { worker } from "captions-mocks/src/browser";
import { handlers } from "captions-mocks/src/handlers";

import { MOCKS_ENABLED_KEY, MOCKS_LOCAL_STORAGE_KEY } from "~/context/MocksProvider";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { createLogLabel } from "~/utils/log";

const { warn, error } = createLogLabel("mocks");

/** Handles both interfacing with the MSW worker and persisting state to `localStorage` */
export function useMocks() {
  const [mocksState, _setMocksState] = useLocalStorage<Record<string, boolean | undefined>>(
    MOCKS_LOCAL_STORAGE_KEY,
    {}
  );

  const isMockingEnabled = mocksState?.[MOCKS_ENABLED_KEY] ?? false;

  const setMockingEnabledGlobally = (mocksEnabled: boolean) => {
    if (isMockingEnabled === mocksEnabled) {
      warn(`Mocking is already ${mocksEnabled ? "enabled" : "disabled"}`);
      return;
    }
    if (mocksEnabled) {
      worker.start();
    } else {
      worker.stop();
    }
    _setMocksState((prev) => ({ ...prev, mocksEnabled }));
  };

  const setMockEnabled = (name: string, enabled: boolean) => {
    if (enabled) {
      const handler = Object.values(handlers)
        .flat()
        .find((handler) => handler.info.header === name);
      if (!handler) {
        error(`Could not find handler ${name}`);
        return;
      }
      worker.use(handler);
    } else {
      const handlers = worker.listHandlers();
      const newHandlers = handlers.filter((handler) => handler.info.header !== name);
      worker.resetHandlers(...newHandlers);
    }

    _setMocksState((prev) => ({ ...prev, [name]: enabled || undefined }));
  };

  return { isMockingEnabled, mocksState, setMockingEnabledGlobally, setMockEnabled };
}
