import * as Dialog from "@radix-ui/react-dialog";
import { useGateValue } from "@statsig/react-bindings";
import { handlers } from "captions-mocks/src/handlers";
import {
  FEATURE_FLAG_NAMES,
  FeatureFlagName,
  isFeatureEnabledWithSource,
} from "feature-flags/isFeatureEnabled";
import { useFeatureFlagOverride } from "feature-flags/useFeatureFlagOverride";
import { useIsEmployee } from "feature-flags/useIsEmployee";
import { useEffect, useRef, useState } from "react";
import { Text } from "ui";
import { useToast } from "ui";

import { RadioGroup } from "~/components/ChipRadioGroup";
import Chevron from "~/components/icons/Chevron";
import { StyledAccordion } from "~/components/StyledAccordion";
import { Switch } from "~/components/Switch";
import { useAuthState } from "~/context/CaptionsAuthContext";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { StudioButton } from "~/modules/studio/StudioButton";
import {
  getDynamicConfigOverride,
  getStatsigStore,
  setDynamicConfigOverride,
} from "~/stores/analytics/statsig";
import { styled } from "~/theme";

import { BugReporter } from "./BugReporter";
import { useSetTweaksMutation } from "./TweaksQueries";
import {
  TweaksTable,
  TweaksDialogContent,
  TweaksItem,
  TweaksContainer,
  TweaksCfgItem,
  TweakCfgText,
} from "./TweaksWidget.styles";
import { useMocks } from "./useMocks";

const DYNAMIC_CONFIG_NAMES = ["client_export_browser_support", "ads_studio_config"] as const;
type DynamicConfigName = (typeof DYNAMIC_CONFIG_NAMES)[number];
const DEFAULT_DYN_CFG_OVERRIDES: Record<DynamicConfigName, object> = {
  client_export_browser_support: {},
  ads_studio_config: {},
};

export function Tweaks() {
  const [isOpen, setIsOpen] = useState(false);
  const isEmployee = useIsEmployee();
  const hasToggleChangedRef = useRef(false);
  const setHasChanged = () => (hasToggleChangedRef.current = true);

  if (!isEmployee) {
    return null;
  }

  // Refresh the page on close if any toggles were changed
  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      hasToggleChangedRef.current = false;
    } else if (hasToggleChangedRef.current) {
      window.location.reload();
    }
  };

  return (
    <TweaksContainer>
      <BugReporter onCloseTweaks={() => setIsOpen(false)} />
      <Dialog.Root onOpenChange={onOpenChange} open={isOpen}>
        <Dialog.Trigger asChild>
          <StudioButton variant="secondary" size="sm">
            🧪
          </StudioButton>
        </Dialog.Trigger>
        <TweaksDialogContent onOpenAutoFocus={(event) => event.preventDefault()} align="top-right">
          <TweaksAccordion setHasChanged={setHasChanged} />
        </TweaksDialogContent>
      </Dialog.Root>
    </TweaksContainer>
  );
}

function TweaksAccordion({ setHasChanged }: { setHasChanged: () => void }) {
  const isDevMode = process.env.NODE_ENV === "development";
  const [sectionsExpanded, setSectionsExpanded] = useLocalStorage("tweaksSectionsExpanded", [
    "feature-flags",
  ]);

  return (
    <StyledAccordion.Root
      type="multiple"
      defaultValue={sectionsExpanded}
      onValueChange={setSectionsExpanded}
    >
      <StyledAccordion.Item value="feature-flags">
        <StyledAccordion.Header css={{ width: "100%" }}>
          <StyledAccordion.Trigger>
            <Text variant="body-2-bold" color="grey-200">
              Feature Flags
            </Text>
            <Chevron />
          </StyledAccordion.Trigger>
        </StyledAccordion.Header>
        <StyledAccordion.Content>
          <TweaksTable>
            <div>
              {FEATURE_FLAG_NAMES.map((name) => (
                <FeatureFlagItem key={name} name={name} onToggle={setHasChanged} />
              ))}
            </div>
          </TweaksTable>
        </StyledAccordion.Content>
      </StyledAccordion.Item>
      <StyledAccordion.Item value="dynamic-configs">
        <StyledAccordion.Header css={{ width: "100%" }}>
          <StyledAccordion.Trigger>
            <Text variant="body-2-bold" color="grey-200">
              Dynamic Configurations
            </Text>
            <Chevron />
          </StyledAccordion.Trigger>
        </StyledAccordion.Header>
        <StyledAccordion.Content>
          <TweaksTable>
            {DYNAMIC_CONFIG_NAMES.map((name) => (
              <DynCfgItem key={name} name={name} onToggle={setHasChanged} />
            ))}
          </TweaksTable>
        </StyledAccordion.Content>
      </StyledAccordion.Item>
      {isDevMode && (
        <StyledAccordion.Item value="mocks">
          <StyledAccordion.Header>
            <StyledAccordion.Trigger>
              <Text variant="body-2-bold" color="grey-200">
                Mocks
              </Text>
              <Chevron />
            </StyledAccordion.Trigger>
          </StyledAccordion.Header>
          <StyledAccordion.Content>
            <TweaksMocks />
          </StyledAccordion.Content>
        </StyledAccordion.Item>
      )}
      <StyledAccordion.Item value="Plan">
        <StyledAccordion.Header>
          <StyledAccordion.Trigger>
            <Text variant="body-2-bold" color="grey-200">
              Subscription
            </Text>
            <Chevron />
          </StyledAccordion.Trigger>
        </StyledAccordion.Header>
        <StyledAccordion.Content>
          <SubscriptionTweak />
        </StyledAccordion.Content>
      </StyledAccordion.Item>
    </StyledAccordion.Root>
  );
}

const STATSIG_BASE_URL = "https://console.statsig.com/1V9mnrUPYt4ksA4maVLS1E/gates/";

function FeatureFlagItem(props: { name: FeatureFlagName; onToggle?: () => void }) {
  // repeat & deconstruct the logic from inside `useFeatureFlag`
  const { name, onToggle } = props;
  const remoteValue = useGateValue(name);
  const [overrideResult, setOverride] = useFeatureFlagOverride(name);
  const [value, source] = isFeatureEnabledWithSource(name, remoteValue, overrideResult);
  const [resultWithoutOverride] = isFeatureEnabledWithSource(name, remoteValue);

  const toggle = () => {
    const newValue = !value;
    if (newValue === resultWithoutOverride) {
      // If the override is redundant, just remove it
      // This provides an implicit way to clear the override from localStorage
      setOverride(undefined);
    } else {
      setOverride(newValue);
    }
    onToggle?.();
  };

  return (
    <TweaksItem>
      <div>
        <code style={{ fontFamily: "monospace" }}>{name}</code>
      </div>

      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Switch checked={value} onCheckedChange={toggle} disabled={source === "url"} />
        <Text
          as="a"
          variant="body-4"
          color={source !== "remote" ? "grey-200" : "grey-600"}
          href={source === "remote" ? STATSIG_BASE_URL + name : undefined}
          target="blank"
          css={{ display: "block", minWidth: "(override)".length + "ch" }}
        >
          ({source})
        </Text>
      </div>
    </TweaksItem>
  );
}

function DynCfgItem(props: { name: DynamicConfigName; onToggle?: () => void }) {
  const { name, onToggle } = props;
  const [value, setValue] = useState<object>(() => DEFAULT_DYN_CFG_OVERRIDES[name]);
  const [overriden, setOverriden] = useState(false);
  const [valueStr, setValueStr] = useState(() => JSON.stringify(value, null, 2));
  const [hasError, setHasError] = useState(false);

  const toggle = (checked: boolean) => {
    if (!checked) {
      setDynamicConfigOverride(name, undefined);
      setOverriden(false);
    } else {
      setDynamicConfigOverride(name, value);
      setOverriden(true);
    }
    onToggle?.();
  };

  const setNewText = (newText: string) => {
    setValueStr(newText);
    try {
      const parsedValue = JSON.parse(newText);
      setValue(parsedValue);
      setHasError(false);
      setDynamicConfigOverride(name, parsedValue);
      onToggle?.();
    } catch (e) {
      setHasError(true);
    }
  };

  useEffect(() => {
    const localConfig = getDynamicConfigOverride<object>(name);
    // Tries to get the local override first
    if (localConfig) {
      setValue(localConfig ?? {});
      setValueStr(JSON.stringify(localConfig, null, 2));
      setOverriden(true);
      return;
    }
    // If no local override, fetch the remote value
    getStatsigStore()
      .getDynamicConfigAsync<object>(name, false)
      .then((config) => {
        setValue(config ?? {});
        setValueStr(JSON.stringify(config ?? {}, null, 2));
        setOverriden(false);
      });
  }, [name]);

  return (
    <TweaksCfgItem>
      <TweaksItem>
        <div>
          <code style={{ fontFamily: "monospace" }}>{name}</code>
        </div>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Switch checked={overriden} onCheckedChange={toggle} />
          <Text
            variant="body-4"
            color={overriden ? "grey-200" : "grey-600"}
            css={{ display: "block", minWidth: "(override)".length + "ch" }}
          >
            ({overriden ? "override" : "remote"})
          </Text>
        </div>
      </TweaksItem>
      <TweakCfgText
        disabled={!overriden}
        value={valueStr}
        hasError={hasError}
        rows={5}
        cols={25}
        onChange={(e) => setNewText(e.target.value)}
      />
    </TweaksCfgItem>
  );
}

function TweaksMocks() {
  const mocks = useMocks();

  return (
    <>
      <Switch
        label="Enable Mocks"
        onCheckedChange={mocks.setMockingEnabledGlobally}
        checked={mocks.isMockingEnabled}
      />
      <TweaksTable>
        {Object.entries(handlers).map(([group, groupHandlers]) => (
          <div key={group}>
            <Text>{group}</Text>
            <div>
              {groupHandlers.map((handler) => {
                const name = handler.info.header;

                return (
                  <MockItem
                    name={name}
                    key={name}
                    checked={mocks.mocksState?.[name]}
                    disabled={!mocks.isMockingEnabled}
                    onToggle={(enabled) => mocks.setMockEnabled(name, enabled)}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </TweaksTable>
    </>
  );
}

const MockItem = (props: {
  name: string;
  onToggle: (enabled: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
}) => {
  const { name, onToggle, disabled, checked } = props;
  return (
    <TweaksItem
      css={{
        opacity: disabled ? 0.4 : undefined,
      }}
    >
      <div>
        <code style={{ fontFamily: "monospace" }}>{name}</code>
      </div>
      <div>
        <Switch checked={checked} onCheckedChange={onToggle} disabled={disabled} />
      </div>
    </TweaksItem>
  );
};

const SubscriptionTweak = () => {
  const { mutateAsync: updateTweak } = useSetTweaksMutation();
  const auth = useAuthState();

  const [plan, setPlan] = useState<string | undefined>(
    auth.user?.subscription?.subscriptionTier
      ? auth.user?.subscription?.subscriptionTier.toLowerCase()
      : "new_customer"
  );

  const [enableStripeTestMode, setEnableStripeTestMode] = useState<boolean | undefined>(undefined);

  const toast = useToast();
  const notifyError = () => toast.add("Unable to update subscription", { severity: "error" });
  const notifySuccess = () =>
    toast.add(`Success! Please refresh after ~1-2 minutes to see changes.`, {
      severity: "success",
      duration: 20000,
    });

  const onSubmit = (params: { subscriptionTier?: string; testMode?: boolean }) => {
    updateTweak({
      subscriptionTier: params.subscriptionTier,
      enableStripeTestMode: params.testMode,
    })
      .then((response) => {
        if (response === true) {
          notifySuccess();
        } else {
          notifyError();
        }
      })
      .catch(notifyError);
  };

  return (
    <div>
      <div>
        <Switch
          label="Enable Checkout in Test Mode"
          onCheckedChange={setEnableStripeTestMode}
          checked={enableStripeTestMode}
          css={{ margin: "$16 0" }}
        />
        <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
          <StudioButton
            variant="secondary-elevated"
            size="sm"
            fullWidth
            onClick={() =>
              onSubmit({ subscriptionTier: undefined, testMode: enableStripeTestMode })
            }
          >
            Save
          </StudioButton>
        </div>
      </div>

      <div>
        <FieldLabel>Override Subscription Tier with Statsig</FieldLabel>
        <RadioGroup.HorizontalRoot
          value={plan}
          onValueChange={setPlan}
          orientation="horizontal"
          color="primary"
          css={{ fontWeight: 600, margin: "$16 0" }}
        >
          {(["pro", "max", "scale", "new_customer"] as const).map((plan, idx) => (
            <RadioGroup.Item key={idx} value={plan} css={{ color: "$white" }}>
              {plan}
            </RadioGroup.Item>
          ))}
        </RadioGroup.HorizontalRoot>

        <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
          <StudioButton
            variant="secondary-elevated"
            size="sm"
            fullWidth
            onClick={() => onSubmit({ subscriptionTier: plan, testMode: undefined })}
          >
            Save
          </StudioButton>
          <div>
            <StudioButton
              variant="secondary-elevated"
              size="sm"
              fullWidth
              onClick={() => onSubmit({ subscriptionTier: "reset", testMode: undefined })}
            >
              Clear Statsig Override
            </StudioButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FieldLabel = styled("div", {
  fontFamily: "$avenir-next",
  fontSize: "$14",
  fontWeight: "$demi-bold",
  lineHeight: "$24",
  letterSpacing: "$spacing-1",
  color: "$grey-200",
  margin: "$16 0",
});
