import * as Switch from "@radix-ui/react-switch";
import { availableColorSchemeVariantStyles, Text } from "ui";

import { styled } from "~/theme";

export const Root = styled(Switch.Root, {
  backgroundColor: "$grey-700",
  borderRadius: "$6",
  width: 32,
  height: 20,
  padding: 0,
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&[data-state='checked']": {
    backgroundColor: "var(--primary-color)",
  },
  flex: "none",
  variants: {
    colorScheme: availableColorSchemeVariantStyles,
  },
  defaultVariants: {
    colorScheme: "purple",
  },
});

export const Thumb = styled(Switch.Thumb, {
  backgroundColor: "$grey-50",
  display: "block",
  height: 16,
  width: 16,
  borderRadius: "$4",
  transition: "transform 150ms cubic-bezier(0.22, 1, 0.36, 1)",
  transform: "translateX(2px)",
  "&[data-state='checked']": {
    transform: "translateX(14px)",
  },
});

export const DescriptionContainer = styled("label", {
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  gap: 8,
  cursor: "pointer",
});

export const LabelAndIconContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  gap: 12,
});

export const Label = styled(Text, {
  defaultVariants: {
    variant: "body-2-bold",
    color: "grey-200",
  },
});

export const Description = styled(Text, {
  defaultVariants: {
    variant: "body-2",
    color: "grey-500",
  },
});
