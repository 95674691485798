import { useEffect, useState } from "react";

import { Button, Text } from "..";
import Link from "../icons/Link";
import Share from "../icons/Share";
import Warning from "../icons/Warning";

import * as S from "./MobileBlocker.styles";
import { MobilePlatformBlockerProps } from "./MobileBlocker.types";

const SHARE_DATA = {
  title: "Captions",
  url: "https://desktop.captions.ai",
};

function MobileNonIOSBlocker({ alwaysBlock }: MobilePlatformBlockerProps) {
  const [hasNativeShare, setHasNativeShare] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleShareLinkClick = () => {
    navigator.share(SHARE_DATA);
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(SHARE_DATA.url);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  useEffect(() => {
    if (typeof navigator !== "undefined" && navigator.canShare?.(SHARE_DATA)) {
      setHasNativeShare(true);
    }
  }, []);

  return (
    <S.MobileBlockerContainer alwaysBlock={alwaysBlock}>
      <S.MobileBlockerContent>
        <S.WarningContainer>
          <Warning />
        </S.WarningContainer>
        <S.NonIOSBlockerTextContainer>
          <Text color="white" variant="heading-4">
            Browser Not Supported
          </Text>
          <S.NonIOSBlockerDescriptionContainer>
            <Text color="grey-400" variant="body-1-bold">
              Try Captions on your computer at
            </Text>
            <Text color="white" variant="body-1-bold">
              desktop.captions.ai
            </Text>
          </S.NonIOSBlockerDescriptionContainer>
          <Text color="grey-400" variant="body-1-bold">
            Our Android app is coming soon!
          </Text>
        </S.NonIOSBlockerTextContainer>

        {hasNativeShare ? (
          <Button onClick={handleShareLinkClick} withIcon>
            <Share />
            Share Link
          </Button>
        ) : (
          <Button onClick={handleCopyLinkClick} withIcon>
            <Link />
            {isCopied ? "Copied!" : "Copy Link"}
          </Button>
        )}
      </S.MobileBlockerContent>
    </S.MobileBlockerContainer>
  );
}

export default MobileNonIOSBlocker;
