import { styled } from "~/theme";

function Share({ ...props }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 6.07079L12.7071 2.77789C12.3166 2.38737 11.6834 2.38737 11.2929 2.77789L8 6.07079"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 3.0708L12 14.0708"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.5 11.0708H7C5.34315 11.0708 4 12.4139 4 14.0708V18.0708C4 19.7277 5.34315 21.0708 7 21.0708H17C18.6569 21.0708 20 19.7277 20 18.0708V14.0708C20 12.4139 18.6569 11.0708 17 11.0708H16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default styled(Share, {
  color: "$grey-100",
  height: "24px",
  width: "24px",
});
