import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { INTERCOM_OPEN } from "~/constants/mixpanel.constants";
import { useIntercom } from "~/context/IntercomContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useEventListener } from "~/hooks/useEventListener";

import Help from "../icons/Help";
import { MobileBlocker } from "../MobileBlocker";
import { Tooltip } from "../Tooltip";
import { TopBar, TopBarContext } from "../TopBar";
import { Tweaks } from "../TweaksWidget/TweaksWidget";

import * as S from "./AppLayout.styles";
import { AppLayoutProps } from "./AppLayout.types";

function denyDragOver(ev: Event) {
  ev.preventDefault();
  ev.stopPropagation();
  if (!("dataTransfer" in ev)) {
    return;
  }
  const dragEvent = ev as DragEvent;
  if (!dragEvent.dataTransfer) {
    return;
  }
  dragEvent.dataTransfer.effectAllowed = "none";
  dragEvent.dataTransfer.dropEffect = "none";
}

function denyDrop(ev: Event) {
  ev.preventDefault();
  ev.stopPropagation();
}

function AppLayout({
  blockMobile = false,
  children,
  hideTopBar = false,
  noPadding = false,
  gradientBackground,
  hideHelpCenter = false,
}: Readonly<AppLayoutProps>) {
  const analytics = useAnalytics();
  const intercom = useIntercom();
  const handleIntercomClick = useCallback(() => {
    if (!intercom.visible) {
      analytics.track(INTERCOM_OPEN);
      intercom.show();
    } else {
      intercom.hide();
    }
  }, [intercom.show, intercom.hide, intercom.visible, analytics.track]);
  const router = useRouter();

  // Prevent the window from accepting dragged files anywhere except for components
  // that explicitly allow it.
  useEventListener(typeof window !== "undefined" ? window : null, "dragover", denyDragOver);
  useEventListener(typeof window !== "undefined" ? window : null, "drop", denyDrop);

  const [topBarElem, setTopBarElem] = useState<HTMLDivElement | undefined>(undefined);

  return (
    <TopBarContext.Provider value={{ topBarElem }}>
      <S.AppLayoutContainer hideTopBar={hideTopBar} gradientBackground={gradientBackground}>
        {blockMobile && <MobileBlocker />}
        {!hideTopBar && <TopBar topBarRef={(elem: HTMLDivElement) => setTopBarElem(elem)} />}
        <S.AppLayoutContentContainer noPadding={noPadding}>{children}</S.AppLayoutContentContainer>
        {!hideHelpCenter && router.pathname !== "/login" && (
          <Tooltip tooltipText="Help Center" unWrapChildren>
            <S.AppLayoutIntercom
              variant={intercom.visible ? "fab-selected" : "fab"}
              onClick={handleIntercomClick}
              size="sm"
              onlyIcon
            >
              <Help />
            </S.AppLayoutIntercom>
          </Tooltip>
        )}
      </S.AppLayoutContainer>
      <Tweaks />
    </TopBarContext.Provider>
  );
}

export default AppLayout;
