import { styled } from "ui";

export const Textarea = styled("textarea", {
  color: "$grey-200",
  borderRadius: "$8",
  border: "1px solid transparent",
  backgroundColor: "transparent",
  fontSize: "$15",
  lineHeight: "$24",
  fontWeight: "$medium",
  letterSpacing: "$spacing-1_5",
  fontFamily: "inherit",
  outline: "none !important",
  "&::placeholder": {
    color: "$grey-500",
    opacity: 1,
  },
  "&:disabled": {
    backgroundColor: "$grey-800",
    color: "$grey-600",
  },
  variants: {
    fullWidth: {
      true: {
        width: "100%",
      },
    },
    fixedWidth: {
      true: {
        resize: "vertical",
      },
    },
    noPadding: {
      true: {
        padding: 0,
      },
      false: { padding: "$16" },
    },
    showsFocus: {
      true: {
        "&:focus": {
          borderColor: "$grey-700",
          boxShadow: "0 0 0 3px $colors$grey-700-40",
        },
      },
    },
    variant: {
      subfield: {
        backgroundColor: "$grey-800",
      },
    },
  },
  defaultVariants: {
    fullWidth: false,
    fixedWidth: false,
    noPadding: false,
    showsFocus: false,
  },
});
