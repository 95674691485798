import { styled } from "ui";

import { BaseDialogContent } from "../BaseDialog";
import { TOP_BAR_HEIGHT } from "../TopBar/TopBar.styles";

export const TweaksContainer = styled("div", {
  position: "fixed",
  bottom: "$32",
  right: "$32",
  zIndex: 999,

  display: "flex",

  gap: "$12",
});

export const TweaksDialogContent = styled(BaseDialogContent, {
  width: "unset !important",
  minWidth: "300px !important",
  maxWidth: "50vw !important",
  maxHeight: `calc(100vh - ${TOP_BAR_HEIGHT} - 24px) !important`,
  margin: "0 24px 0 0 !important",
  padding: "0px !important",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch !important",
});

export const TweaksTable = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
});

export const TweaksItem = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

export const TweaksCfgItem = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const TweakCfgText = styled("textarea", {
  color: "$grey-200",
  borderRadius: "$8",
  border: "1px solid $grey-200",
  backgroundColor: "transparent",
  outline: "none !important",
  fontFamily: "monospace",
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "$medium",
  letterSpacing: "normal",
  resize: "vertical",
  padding: "$8",
  maxHeight: "200px",
  "&:disabled": {
    backgroundColor: "$grey-800",
    color: "$grey-600",
  },
  "&:focus": {
    borderColor: "$grey-700",
    boxShadow: "0 0 0 3px $colors$grey-700-40",
  },
  variants: {
    hasError: {
      true: {
        borderColor: "$secondary-error",
        background: "$error-highlight",
      },
    },
  },
  defaultVariants: {
    hasError: false,
  },
});
