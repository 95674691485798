import { Text } from "..";
import AppStoreDownload from "../icons/AppStoreDownload";
import CaptionsDesktopLogo from "../icons/CaptionsDesktopLogo";
import CaptionsLogoSmall from "../icons/CaptionsLogoSmall";

import * as S from "./MobileBlocker.styles";
import { MobilePlatformBlockerProps } from "./MobileBlocker.types";

const APP_STORE_URL =
  "https://apps.apple.com/us/app/captions-for-talking-videos/id1541407007?platform=iphone";

function MobileIOSBlocker({ alwaysBlock }: MobilePlatformBlockerProps) {
  const handleAppStoreClick = () => {
    window.open(APP_STORE_URL, "_blank");
  };

  return (
    <S.MobileBlockerContainer alwaysBlock={alwaysBlock} gradientBackground>
      <S.MobileBlockerContent>
        <S.LogoSmallContainer>
          <CaptionsLogoSmall />
        </S.LogoSmallContainer>

        <S.LogoDesktopContainer>
          <CaptionsDesktopLogo />
        </S.LogoDesktopContainer>
        <S.IOSBlockerDescriptionContainer>
          <Text color="white" variant="heading-4">
            Download the Captions app
            <br />
            on the App Store!
          </Text>
        </S.IOSBlockerDescriptionContainer>
        <S.AppStoreButton aria-label="Download on the App Store" onClick={handleAppStoreClick}>
          <AppStoreDownload />
        </S.AppStoreButton>
      </S.MobileBlockerContent>
    </S.MobileBlockerContainer>
  );
}

export default MobileIOSBlocker;
