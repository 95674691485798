import { styled } from "~/theme";

import { Button } from "../Button";
import { TOP_BAR_HEIGHT } from "../TopBar/TopBar.styles";

export const largeCircleGradient =
  "radial-gradient(100% 150% at 50% 100%, rgba(40, 6, 94, 0.15) 49.33%, rgba(0,0,0,0) 54%)";

export const spotLightGradient =
  "radial-gradient(28.76% 26.89% at 21.51% 65.27%, rgba(35, 123, 255, 0.10) 0%, rgba(38, 125, 255, 0.00) 100%)";

export const AppLayoutContainer = styled("div", {
  position: "relative",
  backgroundColor: "$grey-900",
  boxSizing: "border-box",
  color: "$grey-150",
  fontFamily: "$avenir-next",
  fontWeight: "$medium",
  letterSpacing: "0.015em",
  paddingTop: `calc(${TOP_BAR_HEIGHT} + 1px)`,
  height: "100vh",
  overflow: "hidden",
  variants: {
    hideTopBar: {
      true: {
        paddingTop: "0",
      },
    },
    gradientBackground: {
      true: {
        background: [largeCircleGradient, spotLightGradient].join(", "),
      },
    },
  },
  defaultVariants: {
    hideTopBar: false,
    gradientBackground: false,
  },
});

export const AppLayoutContentContainer = styled("div", {
  height: "100%",
  padding: "48px",
  overflow: "auto",
  variants: {
    noPadding: {
      true: {
        padding: "0",
      },
    },
  },
  defaultVariants: {
    noPadding: false,
  },
});

export const AppLayoutIntercom = styled(Button, {
  position: "fixed",
  bottom: "24px",
  right: "24px",
  zIndex: 1,
});
