import { styled } from "~/theme";

function AppStoreDownload() {
  return (
    <svg
      width="156"
      height="52"
      viewBox="0 0 156 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.226 0.000169H12.4455C11.9688 0.000169 11.4978 0.000169 11.0224 0.002769C10.6244 0.005369 10.2295 0.012922 9.82772 0.019279C8.95478 0.0295513 8.0839 0.106348 7.22265 0.249002C6.3626 0.394761 5.52949 0.669559 4.7515 1.0641C3.97446 1.46198 3.26446 1.97899 2.64726 2.59636C2.02681 3.212 1.50962 3.92359 1.11557 4.70378C0.720435 5.48241 0.446448 6.31679 0.30307 7.17809C0.158329 8.03831 0.0804429 8.90845 0.07011 9.78069C0.058046 10.1793 0.056772 10.5792 0.050415 10.9778V41.0263C0.056772 41.43 0.058046 41.821 0.07011 42.2248C0.0804461 43.097 0.158332 43.9671 0.30307 44.8273C0.446052 45.6891 0.720056 46.524 1.11557 47.3029C1.50944 48.0805 2.0267 48.7893 2.64726 49.4015C3.26212 50.0216 3.97258 50.5389 4.7515 50.9338C5.52948 51.3294 6.3625 51.6058 7.22265 51.7538C8.08405 51.8953 8.95484 51.9722 9.82772 51.9837C10.2295 51.9925 10.6244 51.9976 11.0224 51.9976C11.4978 52.0002 11.9688 52.0002 12.4455 52.0002H143.226C143.693 52.0002 144.168 52.0002 144.635 51.9976C145.031 51.9976 145.437 51.9925 145.833 51.9837C146.705 51.9728 147.574 51.8959 148.433 51.7538C149.296 51.6048 150.132 51.3284 150.914 50.9338C151.692 50.5387 152.402 50.0214 153.016 49.4015C153.635 48.7869 154.154 48.0787 154.552 47.3029C154.945 46.5234 155.216 45.6886 155.357 44.8273C155.502 43.967 155.583 43.0971 155.598 42.2248C155.604 41.821 155.604 41.43 155.604 41.0263C155.614 40.5541 155.614 40.0844 155.614 39.6044V12.3971C155.614 11.9211 155.614 11.4488 155.604 10.9778C155.604 10.5792 155.604 10.1793 155.598 9.78064C155.583 8.90832 155.502 8.03837 155.357 7.17804C155.216 6.31723 154.945 5.48292 154.552 4.70372C153.75 3.13992 152.477 1.867 150.914 1.06398C150.132 0.670407 149.296 0.395682 148.433 0.248885C147.574 0.105603 146.705 0.0287791 145.833 0.019097C145.437 0.012753 145.031 0.005135 144.635 0.0026C144.168 0 143.693 0.000169 143.226 0.000169Z"
        fill="#A6A6A6"
      />
      <path
        d="M11.0286 50.8625C10.6325 50.8625 10.246 50.8574 9.85303 50.8486C9.03895 50.838 8.22677 50.7671 7.42315 50.6366C6.67382 50.5075 5.94791 50.2675 5.26939 49.9243C4.59708 49.584 3.98388 49.1378 3.45329 48.6027C2.91501 48.074 2.46687 47.4607 2.12662 46.7873C1.78263 46.1094 1.54457 45.3829 1.42072 44.6329C1.28699 43.8271 1.21463 43.0122 1.20427 42.1954C1.19603 41.9212 1.18524 41.0084 1.18524 41.0084V10.9777C1.18524 10.9777 1.19673 10.0788 1.20434 9.81475C1.21425 8.99924 1.28619 8.18566 1.41953 7.38106C1.5436 6.62904 1.78185 5.90039 2.12603 5.22033C2.46502 4.54734 2.91067 3.93363 3.44571 3.403C3.98014 2.86732 4.5953 2.4188 5.26875 2.07379C5.94572 1.73173 6.67025 1.49336 7.41807 1.36666C8.22432 1.2348 9.03932 1.16351 9.85623 1.15339L11.0292 1.13751H144.628L145.815 1.15402C146.625 1.16364 147.432 1.2343 148.231 1.36539C148.987 1.49368 149.719 1.73371 150.403 2.07759C151.753 2.77289 152.85 3.87292 153.543 5.2235C153.882 5.89886 154.116 6.62157 154.239 7.3671C154.374 8.1783 154.449 8.99827 154.465 9.82047C154.468 10.1886 154.468 10.5841 154.468 10.9777C154.479 11.4652 154.479 11.9292 154.479 12.397V39.6043C154.479 40.0765 154.479 40.5374 154.468 41.002C154.468 41.4248 154.468 41.812 154.463 42.2106C154.448 43.0182 154.374 43.8235 154.241 44.6202C154.12 45.3756 153.883 46.1078 153.539 46.7912C153.197 47.4573 152.751 48.0653 152.219 48.5926C151.688 49.1305 151.074 49.5793 150.4 49.9218C149.717 50.2676 148.986 50.5086 148.231 50.6366C147.428 50.7678 146.615 50.8387 145.801 50.8486C145.421 50.8574 145.022 50.8625 144.635 50.8625L143.226 50.8651L11.0286 50.8625Z"
        fill="black"
      />
      <path
        d="M32.2499 26.3907C32.2639 25.3057 32.5521 24.2418 33.0878 23.298C33.6234 22.3543 34.389 21.5614 35.3135 20.993C34.7262 20.1543 33.9515 19.4641 33.0508 18.9772C32.1501 18.4903 31.1483 18.2201 30.125 18.188C27.9419 17.9589 25.8256 19.4943 24.7132 19.4943C23.5792 19.4943 21.8665 18.2108 20.0221 18.2487C18.8292 18.2873 17.6665 18.6342 16.6475 19.2557C15.6284 19.8771 14.7877 20.752 14.2073 21.795C11.6931 26.1479 13.5685 32.5453 15.9769 36.0639C17.1819 37.7868 18.5901 39.7114 20.4328 39.6432C22.236 39.5684 22.9094 38.4933 25.086 38.4933C27.2424 38.4933 27.8743 39.6432 29.7543 39.5998C31.6892 39.5684 32.9083 37.8692 34.071 36.1299C34.9368 34.9022 35.603 33.5454 36.0449 32.1096C34.9208 31.6342 33.9615 30.8384 33.2867 29.8214C32.6118 28.8044 32.2512 27.6112 32.2499 26.3907Z"
        fill="white"
      />
      <path
        d="M28.6989 15.874C29.7538 14.6076 30.2736 12.9797 30.1477 11.3362C28.536 11.5055 27.0471 12.2759 25.9779 13.4937C25.4551 14.0887 25.0547 14.7809 24.7996 15.5307C24.5445 16.2805 24.4396 17.0732 24.4911 17.8635C25.2972 17.8718 26.0948 17.6971 26.8237 17.3525C27.5525 17.0079 28.1937 16.5024 28.6989 15.874Z"
        fill="white"
      />
      <path
        d="M55.0433 35.2814H48.8899L47.4121 39.6447H44.8058L50.6342 23.5013H53.3421L59.1705 39.6447H56.5197L55.0433 35.2814ZM49.5272 33.2679H54.4048L52.0003 26.1864H51.933L49.5272 33.2679Z"
        fill="white"
      />
      <path
        d="M71.758 33.7605C71.758 37.4181 69.8004 39.768 66.8462 39.768C66.0978 39.8071 65.3536 39.6347 64.6987 39.2705C64.0437 38.9064 63.5046 38.3651 63.143 37.7088H63.0871V43.5384H60.6711V27.875H63.0097V29.8326H63.0541C63.4323 29.1794 63.9806 28.6409 64.6405 28.2745C65.3004 27.9081 66.0473 27.7275 66.8017 27.7518C69.7889 27.7518 71.758 30.1132 71.758 33.7605ZM69.2748 33.7605C69.2748 31.3776 68.0433 29.811 66.1644 29.811C64.3185 29.811 63.0769 31.4106 63.0769 33.7605C63.0769 36.132 64.3185 37.7202 66.1644 37.7202C68.0433 37.7202 69.2748 36.165 69.2748 33.7605Z"
        fill="white"
      />
      <path
        d="M84.7122 33.7605C84.7122 37.418 82.7546 39.7679 79.8004 39.7679C79.0521 39.8071 78.3078 39.6347 77.6529 39.2705C76.9979 38.9063 76.4588 38.3651 76.0972 37.7087H76.0413V43.5384H73.6254V27.875H75.9638V29.8326H76.0083C76.3865 29.1794 76.9348 28.6409 77.5947 28.2745C78.2546 27.9081 79.0015 27.7275 79.7559 27.7518C82.7432 27.7518 84.7122 30.1132 84.7122 33.7605ZM82.229 33.7605C82.229 31.3776 80.9976 29.811 79.1187 29.811C77.2728 29.811 76.0312 31.4106 76.0312 33.7605C76.0312 36.132 77.2728 37.7202 79.1187 37.7202C80.9976 37.7202 82.229 36.165 82.229 33.7605Z"
        fill="white"
      />
      <path
        d="M93.274 35.1468C93.453 36.7477 95.0082 37.7988 97.1334 37.7988C99.1697 37.7988 100.635 36.7477 100.635 35.3042C100.635 34.0512 99.7511 33.3009 97.659 32.7867L95.5668 32.2827C92.6024 31.5667 91.2262 30.1804 91.2262 27.9307C91.2262 25.1454 93.6536 23.2322 97.1004 23.2322C100.512 23.2322 102.85 25.1454 102.929 27.9307H100.49C100.344 26.3197 99.0122 25.3473 97.0661 25.3473C95.1199 25.3473 93.7881 26.3311 93.7881 27.7632C93.7881 28.9045 94.6387 29.5761 96.7195 30.0902L98.4981 30.5269C101.81 31.3102 103.186 32.6407 103.186 35.002C103.186 38.0222 100.781 39.9138 96.9543 39.9138C93.3742 39.9138 90.9571 38.0666 90.8009 35.1467L93.274 35.1468Z"
        fill="white"
      />
      <path
        d="M108.4 25.0895V27.8749H110.639V29.7881H108.4V36.2766C108.4 37.2847 108.849 37.7544 109.832 37.7544C110.098 37.7498 110.363 37.7311 110.627 37.6985V39.6002C110.185 39.6829 109.735 39.7203 109.285 39.712C106.902 39.712 105.973 38.8169 105.973 36.5343V29.7881H104.262V27.8749H105.973V25.0895H108.4Z"
        fill="white"
      />
      <path
        d="M111.935 33.7604C111.935 30.0572 114.116 27.7302 117.517 27.7302C120.93 27.7302 123.1 30.0572 123.1 33.7604C123.1 37.4738 120.941 39.7907 117.517 39.7907C114.094 39.7907 111.935 37.4738 111.935 33.7604ZM120.639 33.7604C120.639 31.2201 119.475 29.7208 117.517 29.7208C115.559 29.7208 114.396 31.2315 114.396 33.7604C114.396 36.3109 115.559 37.7988 117.517 37.7988C119.475 37.7988 120.639 36.3109 120.639 33.7604Z"
        fill="white"
      />
      <path
        d="M125.092 27.8749H127.396V29.8782H127.452C127.608 29.2525 127.975 28.6996 128.49 28.3123C129.006 27.9251 129.639 27.7271 130.283 27.7517C130.562 27.7507 130.839 27.781 131.111 27.8419V30.1016C130.76 29.9942 130.393 29.9449 130.026 29.9556C129.675 29.9414 129.325 30.0033 129 30.137C128.675 30.2708 128.383 30.4732 128.144 30.7305C127.905 30.9877 127.724 31.2937 127.614 31.6274C127.505 31.9611 127.468 32.3145 127.508 32.6635V39.6447H125.092L125.092 27.8749Z"
        fill="white"
      />
      <path
        d="M142.25 36.1878C141.925 38.3244 139.844 39.7907 137.182 39.7907C133.758 39.7907 131.633 37.4967 131.633 33.8163C131.633 30.1245 133.769 27.7302 137.08 27.7302C140.337 27.7302 142.385 29.9671 142.385 33.5357V34.3635H134.072V34.5095C134.033 34.9426 134.088 35.379 134.231 35.7896C134.374 36.2002 134.603 36.5756 134.902 36.8909C135.202 37.2062 135.565 37.4542 135.968 37.6184C136.37 37.7826 136.803 37.8592 137.238 37.8432C137.809 37.8967 138.382 37.7645 138.871 37.4662C139.361 37.168 139.742 36.7196 139.956 36.1878L142.25 36.1878ZM134.083 32.675H139.967C139.989 32.2855 139.93 31.8958 139.794 31.5302C139.658 31.1646 139.448 30.8311 139.177 30.5506C138.905 30.2701 138.579 30.0487 138.219 29.9002C137.858 29.7517 137.47 29.6794 137.08 29.6878C136.687 29.6854 136.297 29.761 135.933 29.9102C135.569 30.0594 135.238 30.2791 134.959 30.5569C134.681 30.8346 134.46 31.1647 134.309 31.5283C134.159 31.8918 134.082 32.2816 134.083 32.675Z"
        fill="white"
      />
      <path
        d="M49.2244 11.3503C49.7309 11.3139 50.2391 11.3905 50.7125 11.5743C51.1858 11.7582 51.6125 12.0448 51.9616 12.4135C52.3108 12.7822 52.5738 13.2238 52.7316 13.7065C52.8894 14.1892 52.9382 14.7008 52.8743 15.2046C52.8743 17.6827 51.535 19.1072 49.2244 19.1072H46.4225V11.3503H49.2244ZM47.6273 18.0102H49.0898C49.4517 18.0318 49.8139 17.9725 50.15 17.8365C50.4861 17.7005 50.7877 17.4913 51.0327 17.224C51.2778 16.9568 51.4601 16.6383 51.5666 16.2917C51.673 15.9451 51.7008 15.5791 51.6479 15.2204C51.6969 14.8631 51.6663 14.4994 51.5583 14.1553C51.4502 13.8113 51.2674 13.4954 51.0229 13.2303C50.7784 12.9652 50.4783 12.7574 50.1441 12.6219C49.8099 12.4864 49.4499 12.4265 49.0898 12.4465H47.6273V18.0102Z"
        fill="white"
      />
      <path
        d="M54.2352 16.1777C54.1984 15.793 54.2424 15.4049 54.3644 15.0382C54.4864 14.6715 54.6837 14.3343 54.9436 14.0484C55.2035 13.7624 55.5204 13.5339 55.8738 13.3775C56.2272 13.2212 56.6094 13.1404 56.9958 13.1404C57.3823 13.1404 57.7645 13.2212 58.1179 13.3775C58.4713 13.5339 58.7881 13.7624 59.048 14.0484C59.3079 14.3343 59.5052 14.6715 59.6272 15.0382C59.7492 15.4049 59.7932 15.793 59.7564 16.1777C59.7939 16.5628 59.7504 16.9515 59.6288 17.3187C59.5071 17.686 59.3099 18.0238 59.0499 18.3103C58.7899 18.5969 58.4729 18.8258 58.1191 18.9825C57.7654 19.1392 57.3827 19.2202 56.9958 19.2202C56.6089 19.2202 56.2263 19.1392 55.8725 18.9825C55.5188 18.8258 55.2017 18.5969 54.9417 18.3103C54.6817 18.0238 54.4845 17.686 54.3629 17.3187C54.2412 16.9515 54.1977 16.5628 54.2352 16.1777ZM58.5681 16.1777C58.5681 14.9088 57.9981 14.1668 56.9977 14.1668C55.9935 14.1668 55.4286 14.9088 55.4286 16.1777C55.4286 17.4568 55.9936 18.1931 56.9977 18.1931C57.9981 18.1931 58.5681 17.4517 58.5681 16.1777Z"
        fill="white"
      />
      <path
        d="M67.0956 19.107H65.8972L64.6873 14.7957H64.5959L63.3911 19.107H62.2041L60.5905 13.2532H61.7623L62.8109 17.72H62.8973L64.1008 13.2532H65.2091L66.4126 17.72H66.504L67.5475 13.2532H68.7028L67.0956 19.107Z"
        fill="white"
      />
      <path
        d="M70.0599 13.2533H71.172V14.1832H71.2584C71.4048 13.8492 71.6518 13.5693 71.965 13.3823C72.2781 13.1954 72.6418 13.1108 73.0053 13.1403C73.2901 13.1189 73.5761 13.1618 73.8421 13.266C74.108 13.3701 74.3472 13.5327 74.5418 13.7418C74.7364 13.9509 74.8814 14.2011 74.9662 14.4739C75.0509 14.7467 75.0732 15.035 75.0314 15.3176V19.1071H73.8762V15.6077C73.8762 14.6669 73.4674 14.1991 72.613 14.1991C72.4196 14.1901 72.2265 14.223 72.0471 14.2956C71.8676 14.3682 71.7059 14.4787 71.5732 14.6196C71.4404 14.7605 71.3397 14.9284 71.2779 15.1119C71.2161 15.2954 71.1947 15.49 71.2152 15.6826V19.1071H70.0599L70.0599 13.2533Z"
        fill="white"
      />
      <path d="M76.8723 10.9681H78.0275V19.107H76.8723V10.9681Z" fill="white" />
      <path
        d="M79.6335 16.1777C79.5967 15.7929 79.6408 15.4048 79.7628 15.0381C79.8848 14.6714 80.0821 14.3342 80.3421 14.0482C80.602 13.7622 80.9189 13.5338 81.2723 13.3774C81.6257 13.221 82.008 13.1403 82.3944 13.1403C82.7809 13.1403 83.1631 13.221 83.5165 13.3774C83.87 13.5338 84.1868 13.7622 84.4468 14.0482C84.7067 14.3342 84.904 14.6714 85.0261 15.0381C85.1481 15.4048 85.1921 15.7929 85.1553 16.1777C85.1928 16.5628 85.1493 16.9514 85.0276 17.3187C84.9059 17.686 84.7086 18.0238 84.4486 18.3103C84.1886 18.5968 83.8715 18.8258 83.5178 18.9825C83.164 19.1392 82.7813 19.2201 82.3944 19.2201C82.0075 19.2201 81.6249 19.1392 81.2711 18.9825C80.9173 18.8258 80.6002 18.5968 80.3402 18.3103C80.0802 18.0238 79.883 17.686 79.7613 17.3187C79.6396 16.9514 79.5961 16.5628 79.6335 16.1777ZM83.9664 16.1777C83.9664 14.9088 83.3964 14.1667 82.396 14.1667C81.3918 14.1667 80.8269 14.9088 80.8269 16.1777C80.8269 17.4567 81.3919 18.1931 82.396 18.1931C83.3964 18.193 83.9664 17.4516 83.9664 16.1777Z"
        fill="white"
      />
      <path
        d="M86.3715 17.4516C86.3715 16.3979 87.1561 15.7904 88.5488 15.7041L90.1344 15.6127V15.1074C90.1344 14.4891 89.7256 14.14 88.936 14.14C88.2911 14.14 87.8442 14.3768 87.716 14.7906H86.5975C86.7156 13.7852 87.6614 13.1403 88.9893 13.1403C90.4569 13.1403 91.2846 13.8709 91.2846 15.1074V19.1071H90.1725V18.2844H90.0811C89.8956 18.5795 89.635 18.8201 89.3261 18.9815C89.0172 19.143 88.671 19.2196 88.3228 19.2035C88.077 19.2291 87.8287 19.2028 87.5937 19.1265C87.3587 19.0502 87.1423 18.9255 86.9585 18.7605C86.7746 18.5954 86.6274 18.3936 86.5263 18.1682C86.4252 17.9428 86.3725 17.6986 86.3715 17.4516ZM90.1344 16.9514V16.462L88.7049 16.5534C87.8988 16.6073 87.5331 16.8816 87.5331 17.3976C87.5331 17.9245 87.9902 18.2311 88.6186 18.2311C88.8028 18.2497 88.9888 18.2311 89.1656 18.1764C89.3424 18.1217 89.5064 18.0319 89.6478 17.9126C89.7892 17.7932 89.9052 17.6465 89.9888 17.4814C90.0724 17.3163 90.1219 17.136 90.1344 16.9514Z"
        fill="white"
      />
      <path
        d="M92.803 16.1776C92.803 14.3279 93.7539 13.1561 95.2329 13.1561C95.5987 13.1393 95.9618 13.2269 96.2797 13.4088C96.5976 13.5906 96.8571 13.8592 97.028 14.1831H97.1143V10.9681H98.2696V19.107H97.1626V18.1822H97.0712C96.8871 18.5039 96.6185 18.7692 96.2944 18.9492C95.9704 19.1292 95.6033 19.2172 95.2329 19.2035C93.7437 19.2036 92.803 18.0318 92.803 16.1776ZM93.9964 16.1776C93.9964 17.4192 94.5816 18.1663 95.5604 18.1663C96.5341 18.1663 97.1359 17.4084 97.1359 16.1827C97.1359 14.9627 96.5278 14.194 95.5604 14.194C94.5879 14.194 93.9964 14.9462 93.9964 16.1776Z"
        fill="white"
      />
      <path
        d="M103.049 16.1777C103.013 15.793 103.057 15.4049 103.179 15.0382C103.301 14.6715 103.498 14.3343 103.758 14.0484C104.018 13.7624 104.334 13.5339 104.688 13.3775C105.041 13.2212 105.423 13.1404 105.81 13.1404C106.196 13.1404 106.579 13.2212 106.932 13.3775C107.285 13.5339 107.602 13.7624 107.862 14.0484C108.122 14.3343 108.319 14.6715 108.441 15.0382C108.563 15.4049 108.607 15.793 108.571 16.1777C108.608 16.5628 108.565 16.9515 108.443 17.3187C108.321 17.686 108.124 18.0238 107.864 18.3103C107.604 18.5969 107.287 18.8258 106.933 18.9825C106.579 19.1392 106.197 19.2202 105.81 19.2202C105.423 19.2202 105.04 19.1392 104.687 18.9825C104.333 18.8258 104.016 18.5969 103.756 18.3103C103.496 18.0238 103.299 17.686 103.177 17.3187C103.055 16.9515 103.012 16.5628 103.049 16.1777ZM107.382 16.1777C107.382 14.9088 106.812 14.1668 105.812 14.1668C104.808 14.1668 104.243 14.9088 104.243 16.1777C104.243 17.4568 104.808 18.1931 105.812 18.1931C106.812 18.1931 107.382 17.4517 107.382 16.1777Z"
        fill="white"
      />
      <path
        d="M110.121 13.2533H111.233V14.1832H111.319C111.466 13.8492 111.713 13.5693 112.026 13.3823C112.339 13.1954 112.702 13.1108 113.066 13.1403C113.351 13.1189 113.637 13.1618 113.903 13.266C114.169 13.3701 114.408 13.5327 114.602 13.7418C114.797 13.9509 114.942 14.2011 115.027 14.4739C115.112 14.7467 115.134 15.035 115.092 15.3176V19.1071H113.937V15.6077C113.937 14.6669 113.528 14.1991 112.674 14.1991C112.48 14.1901 112.287 14.223 112.108 14.2956C111.928 14.3682 111.767 14.4787 111.634 14.6196C111.501 14.7605 111.4 14.9284 111.339 15.1119C111.277 15.2954 111.255 15.49 111.276 15.6826V19.1071H110.121V13.2533Z"
        fill="white"
      />
      <path
        d="M121.62 11.7958V13.2799H122.888V14.253H121.62V17.263C121.62 17.8762 121.873 18.1447 122.448 18.1447C122.595 18.1443 122.742 18.1354 122.888 18.1181V19.0804C122.681 19.1175 122.471 19.1372 122.26 19.1394C120.975 19.1394 120.464 18.6874 120.464 17.5588V14.2529H119.534V13.2798H120.464V11.7958H121.62Z"
        fill="white"
      />
      <path
        d="M124.466 10.9681H125.611V14.194H125.703C125.856 13.8568 126.11 13.5753 126.43 13.3876C126.749 13.2 127.119 13.1154 127.488 13.1453C127.771 13.1299 128.054 13.1772 128.317 13.2839C128.58 13.3905 128.817 13.5539 129.009 13.7623C129.202 13.9708 129.346 14.2191 129.431 14.4897C129.517 14.7603 129.542 15.0464 129.504 15.3277V19.1071H128.347V15.6127C128.347 14.6776 127.912 14.2041 127.096 14.2041C126.897 14.1878 126.697 14.2151 126.51 14.2841C126.324 14.3531 126.154 14.462 126.014 14.6034C125.873 14.7447 125.765 14.9151 125.698 15.1025C125.63 15.2898 125.604 15.4897 125.622 15.6882V19.107H124.466L124.466 10.9681Z"
        fill="white"
      />
      <path
        d="M136.24 17.5264C136.083 18.0614 135.743 18.5242 135.279 18.8338C134.816 19.1434 134.258 19.2801 133.703 19.22C133.318 19.2302 132.935 19.1563 132.58 19.0035C132.226 18.8507 131.909 18.6227 131.652 18.3352C131.395 18.0477 131.203 17.7077 131.09 17.3387C130.978 16.9698 130.947 16.5807 130.999 16.1985C130.948 15.8152 130.98 15.4252 131.092 15.0552C131.204 14.6851 131.395 14.3435 131.651 14.0535C131.907 13.7635 132.222 13.5319 132.575 13.3743C132.929 13.2167 133.312 13.1369 133.698 13.1402C135.327 13.1402 136.31 14.253 136.31 16.0912V16.4943H132.176V16.5591C132.158 16.7739 132.185 16.9901 132.255 17.1939C132.326 17.3977 132.438 17.5845 132.585 17.7424C132.732 17.9003 132.91 18.0257 133.108 18.1108C133.306 18.1958 133.52 18.2385 133.735 18.2361C134.012 18.2692 134.291 18.2195 134.539 18.0931C134.787 17.9667 134.992 17.7695 135.128 17.5264L136.24 17.5264ZM132.176 15.6399H135.133C135.147 15.4434 135.121 15.2461 135.055 15.0606C134.988 14.875 134.884 14.7054 134.748 14.5626C134.613 14.4198 134.448 14.3069 134.267 14.2313C134.085 14.1557 133.889 14.1189 133.692 14.1235C133.492 14.1209 133.294 14.1585 133.109 14.2338C132.924 14.3091 132.756 14.4207 132.614 14.5621C132.473 14.7034 132.362 14.8716 132.286 15.0567C132.211 15.2418 132.174 15.4401 132.176 15.6399Z"
        fill="white"
      />
    </svg>
  );
}

export default styled(AppStoreDownload, {
  height: "52px",
  width: "156px",
});
