import { styled } from "~/theme";

const gradientSolidBackground = "linear-gradient(180deg, $primary-dark 0%, $primary-dark 100%)";

const largeCircleGradient =
  "radial-gradient(100% 150% at 50% 100%, rgba(40, 6, 94, 0.15) 49.33%, rgba(0,0,0,0) 54%)";

const spotLightGradient =
  "radial-gradient(28.76% 26.89% at 21.51% 65.27%, rgba(35, 123, 255, 0.10) 0%, rgba(38, 125, 255, 0.00) 100%)";

export const MobileBlockerContainer = styled("div", {
  display: "none",
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100vh",
  backgroundColor: "$grey-900",
  zIndex: "$z-dialog",
  "@bp1": {
    display: "block",
  },
  variants: {
    alwaysBlock: {
      true: {
        display: "block",
      },
    },
    gradientBackground: {
      true: {
        background: [largeCircleGradient, spotLightGradient, gradientSolidBackground].join(", "),
      },
    },
  },
  defaultVariants: {
    gradientBackground: false,
  },
});

export const MobileBlockerContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

export const WarningContainer = styled("div", {
  backgroundColor: "$primary-purple_12",
  borderRadius: 8,
  marginBottom: 22,
  padding: 8,
  "& > svg": {
    color: "$primary-purple",
  },
});

export const LogoSmallContainer = styled("div", {
  backgroundColor: "$primary-dark",
  borderRadius: 30,
  marginBottom: 24,
  padding: 30,
  "& > svg": {
    color: "$white",
  },
});

export const LogoDesktopContainer = styled("div", {
  marginBottom: 32,
  "& > svg": {
    height: 32,
  },
});

export const NonIOSBlockerTextContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  marginBottom: 32,
});

export const NonIOSBlockerDescriptionContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

export const IOSBlockerDescriptionContainer = styled("div", {
  textAlign: "center",
  marginBottom: 84,
});

export const AppStoreButton = styled("button", {
  padding: 0,
});
