import { z } from "zod";

import { createUseMutationHook } from "~/utils/network/query-helpers";

export const useSetTweaksMutation = createUseMutationHook({
  path: "internal/v1/tweaks",
  paramsSchema: z.object({
    subscriptionTier: z.string().optional(),
    enableStripeTestMode: z.boolean().optional(),
  }),
  responseSchema: z.boolean(),
  proxied: false,
});
