import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { z } from "zod";

import { StudioButton } from "~/modules/studio/StudioButton";
import { styled } from "~/theme";
import { createUseMutationHook } from "~/utils/network/query-helpers";
import { mutationSucceededSchema } from "~/utils/network/shared-schemas";

import { BaseDialogContent } from "../BaseDialog";
import Close from "../icons/Close";
import { Text } from "../Text";
import { Textarea } from "../Textarea";
import { TextInput } from "../TextInput";
import { useToast } from "../Toast";

const useSubmitReportBugMutation = createUseMutationHook({
  path: "/internal/v1/report-bug",
  // we use FormData because we need to upload a file
  paramsSchema: z.instanceof(FormData),
  responseSchema: mutationSucceededSchema,
  proxied: false,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export function BugReporter({ onCloseTweaks }: { onCloseTweaks: () => void }) {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [imageData, setImageData] = useState<string>();

  const { mutate: submitReportBug, isPending } = useSubmitReportBugMutation({
    onSuccess(response) {
      if (response.succeeded) {
        setIsOpen(false);
        setTitle("");
        setDescription("");
        setImageData(undefined);

        toast.add("Bug reported successfully", {
          severity: "success",
          duration: 5_000,
        });
      } else {
        toast.add("Failed to report bug", {
          severity: "error",
          duration: 5_000,
        });
      }
    },
    onError() {
      toast.add("Failed to report bug", {
        severity: "error",
        duration: 5_000,
      });
    },
  });

  const onOpenChange = (open: boolean) => {
    if (open) {
      onCloseTweaks();
    }

    setIsOpen(open);
  };

  const onCaptureScreenshot = async () => {
    setIsOpen(false);

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        preferCurrentTab: true,
      } as DisplayMediaStreamOptions);

      const video = document.createElement("video");
      video.srcObject = stream;
      await video.play();

      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx!.drawImage(video, 0, 0);

      const imageData = canvas.toDataURL("image/png");
      stream.getTracks().forEach((track) => track.stop());

      setImageData(imageData);
    } catch (err) {
      console.error("Error capturing screen:", err);
    } finally {
      setIsOpen(true);
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    formData.append("currentPage", window.location.pathname);

    if (imageData) {
      // this turns the base 64 string to a [File] object
      const res = await fetch(imageData);
      const buff = await res.arrayBuffer();
      // clone so we can rename, and put into array for easy proccessing

      formData.append(
        "screenshot",
        new File([buff], `screenshot.png`, {
          type: "image/png",
        })
      );
    }

    submitReportBug(formData);
  };

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={isOpen}>
      <Dialog.Trigger asChild>
        <StudioButton variant="secondary" size="sm">
          🐛
        </StudioButton>
      </Dialog.Trigger>
      <BaseDialogContent css={{ maxWidth: "unset !important", width: "600px" }} padding="small">
        <Dialog.Close asChild>
          <StudioButton
            css={{ position: "absolute", top: 8, right: 8 }}
            variant="tertiary"
            icon={<Close />}
            iconPosition="only"
            size="sm"
          />
        </Dialog.Close>
        <Form onSubmit={onSubmit}>
          <Fieldset disabled={isPending}>
            <div>
              <Text variant="heading-4" as="h4">
                Report something buggy
              </Text>
              <Text variant="body-1-subtle">
                This will create a ticket in Linear for the Desktop team to investigate.
              </Text>
            </div>

            <TextInput
              fullWidth
              placeholder="Issue title (required)"
              variant="subfield"
              showsFocus
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <Textarea
              fullWidth
              placeholder="What happened? What went wrong? Please describe in detail."
              variant="subfield"
              css={{ resize: "none" }}
              rows={10}
              showsFocus
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Footer>
              {imageData ? (
                <ScreenshotPreviewContainer>
                  <ScreenshotPreview src={imageData} alt="Screenshot" />
                  <DeleteButton onClick={() => setImageData(undefined)}>
                    <Close />
                  </DeleteButton>
                </ScreenshotPreviewContainer>
              ) : (
                <StudioButton
                  variant="secondary"
                  size="sm"
                  onClick={onCaptureScreenshot}
                  type="button"
                >
                  Capture Screenshot
                </StudioButton>
              )}
              <StudioButton variant="primary" size="sm" type="submit" isPending={isPending}>
                Submit
              </StudioButton>
            </Footer>
          </Fieldset>
        </Form>
      </BaseDialogContent>
    </Dialog.Root>
  );
}

const Form = styled("form", {
  width: "100%",
});

const Fieldset = styled("fieldset", {
  display: "flex",
  flexDirection: "column",
  gap: "$16",

  width: "100%",
});

const Footer = styled("footer", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ScreenshotPreviewContainer = styled("div", {
  position: "relative",
});

const DeleteButton = styled("button", {
  position: "absolute",
  top: "-$8",
  right: "-$8",

  display: "none",
  width: "24px",
  height: "24px",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px",

  border: "none",
  cursor: "pointer",
  background: "rgba(0, 0, 0, 0.5)",
  backdropFilter: "blur(10px)",
  borderRadius: "100%",

  [`${ScreenshotPreviewContainer}:hover &`]: {
    display: "flex",
  },
});

const ScreenshotPreview = styled("img", {
  display: "block",
  height: "40px",
  border: "1px solid $colors$grey-700",
  borderRadius: "$4",
});
