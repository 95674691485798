import { styled } from "ui";

export const TextInputField = styled("input", {
  all: "unset",
  fontSize: "$15",
  lineHeight: "$24",
  fontWeight: "$medium",
  flex: "1 1 0",
  color: "$grey-200",
  textOverflow: "ellipsis",
  paddingTop: "1px",
  "&::placeholder": {
    color: "$grey-500",
  },
  variants: {
    centerUnfocused: {
      true: {
        "&:not(:focus)": {
          textAlign: "center",
        },
      },
    },
  },
});

export const TextInputContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: 8,
  padding: "10px 14px",
  borderRadius: 6,
  border: "1px solid $grey-800",
  cursor: "text",
  color: "$grey-500",
  "& input::placeholder": {
    color: "$grey-500",
    opacity: 1,
  },
  "& input:disabled": {
    color: "$grey-600",
  },
  "&:hover, &:focus, &:focus-within, &:active": {
    color: "$grey-200",
    backgroundColor: "$grey-800",
  },
  variants: {
    hasText: {
      true: {
        color: "$grey-200",
      },
    },
    fullWidth: {
      true: {
        width: "100%",
      },
    },
    morePadding: {
      true: {
        padding: "10px 16px",
      },
    },
    variant: {
      subfield: {
        backgroundColor: "$grey-800",
        "& svg": {
          color: "$grey-600",
        },
        "& input:not(:disabled)": {
          color: "$grey-200",
        },
      },
      ["subfield-comfortable"]: {
        backgroundColor: "$grey-800",
        padding: "10px 16px",
        "& input:not(:disabled)": {
          color: "$grey-200",
        },
      },
    },
    hasError: {
      true: {
        borderColor: "$secondary-error",
        background: "$error-highlight",
      },
    },
    showsFocus: {
      true: {
        "&:focus-within": {
          borderColor: "$grey-700",
          boxShadow: "0 0 0 3px $colors$grey-700-40",
        },
      },
    },
  },
  "input::-webkit-search-cancel-button": {
    display: "none",
  },
});

export const TextInputIcon = styled("div", {
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
