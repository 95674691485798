import { Root, Portal, Content } from "@radix-ui/react-dialog";
import { useFeatureFlag } from "feature-flags";
import { useEffect, useState } from "react";

import useMediaQuery from "~/hooks/useMediaQuery";

import {
  AndroidUserAgentCode,
  BlockedDevices,
  IOSUserAgentCode,
  MacUserAgentCode,
} from "./MobileBlocker.types";
import MobileIOSBlocker from "./MobileIOSBlocker";
import MobileNonIOSBlocker from "./MobileNonIOSBlocker";

const iOSUserAgentCodes = ["iPad", "iPhone", "iPod"] satisfies Array<IOSUserAgentCode>;

export const MacUserAgentCodes = [
  "Macintosh",
  "MacIntel",
  "MacPPC",
  "Mac68K",
] satisfies Array<MacUserAgentCode>;

const androidUserAgentCodes = ["Android"] satisfies Array<AndroidUserAgentCode>;

function MobileBlocker() {
  const [isUserOnBlockedList, setIsUserOnBlockedList] = useState<BlockedDevices | null>(null);
  const isFullMobileBlockerEnabled = useFeatureFlag("full_mobile_blocker");

  const getIsUserOnIOS = () => {
    return iOSUserAgentCodes.some((code) => navigator.userAgent.includes(code));
  };

  const getIsUserOnMac = () => {
    return MacUserAgentCodes.some((code) => navigator.userAgent.includes(code));
  };

  const getIsUserOnAndroid = () => {
    return androidUserAgentCodes.some((code) => navigator.userAgent.includes(code));
  };

  const blockerEnabledForSize = useMediaQuery("(max-width: 768px)");

  const alwaysBlock =
    isFullMobileBlockerEnabled &&
    (isUserOnBlockedList === "ios" || isUserOnBlockedList === "android");

  useEffect(() => {
    if (typeof navigator !== "undefined") {
      const isUserOnIOS = getIsUserOnIOS();
      if (isUserOnIOS) {
        setIsUserOnBlockedList("ios");
        return;
      }
      const isUserOnMac = getIsUserOnMac();
      if (isUserOnMac) {
        setIsUserOnBlockedList("mac");
        return;
      }
      const isUserOnAndroid = getIsUserOnAndroid();
      if (isUserOnAndroid) {
        setIsUserOnBlockedList("android");
        return;
      }
    }
  }, []);

  return (
    <Root open={alwaysBlock || blockerEnabledForSize} modal>
      <Portal>
        <Content>
          {isUserOnBlockedList === "ios" || isUserOnBlockedList === "mac" ? (
            <MobileIOSBlocker alwaysBlock={alwaysBlock} />
          ) : (
            <MobileNonIOSBlocker alwaysBlock={alwaysBlock} />
          )}
        </Content>
      </Portal>
    </Root>
  );
}

export default MobileBlocker;
