import * as RadixSwitch from "@radix-ui/react-switch";

import * as S from "./Switch.styles";
import { SwitchProps } from "./Switch.types";

const { Root: _radixRoot, Thumb: _radixThumb } = RadixSwitch;

/** A toggle switch with optional label and description text */
export function Switch({ css, label, description, icon, labelOnly, ...props }: SwitchProps) {
  return (
    <S.DescriptionContainer css={css}>
      <S.LabelAndIconContainer>
        {icon}
        {(label || description) && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <S.Label>{label}</S.Label>
            {description && <S.Description>{description}</S.Description>}
          </div>
        )}
      </S.LabelAndIconContainer>
      {!labelOnly && (
        <S.Root {...props}>
          <S.Thumb />
        </S.Root>
      )}
    </S.DescriptionContainer>
  );
}

export default Switch;
